import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import ProcessComponent from "../components/custom/process-component";

const Process = () => {
  return (
    <Layout>
      <Seo title="Process" />
      <div className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-8 ml-auto mr-auto text-center">
              <h2 className="title">Nos Process</h2>
              <h5 className="description">
                <b>
                  Dans notre engagement à fournir des produits composites de
                  qualité supérieure, Carthage Composites Distribution met en
                  œuvre une expertise pointue dans une variété de processus de
                  fabrication. Chaque étape de notre processus est soigneusement
                  exécutée pour garantir la création de pièces composites
                  durables et performantes. Découvrez ci-dessous un aperçu de
                  quelques-uns de nos processus clés qui nous permettent de
                  répondre aux besoins variés de nos clients.
                </b>
              </h5>
              <br />
            </div>
          </div>
        </div>
      </div>
      <ProcessComponent />
    </Layout>
  );
};

export default Process;
